import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import { EstimateResponse, OrderEntity } from '@patient-ui/shared/models';

import * as PatientOrdersActions from './patient-orders.actions';

export const patientOrdersFeatureKey = 'patientOrders';

export interface PatientOrdersState {
  patientOrders: OrderEntity[];
  loadPatientOrdersPayloadRequestStatus: RequestStatus;
  patientEstimate: EstimateResponse | null;
  loadPatientEstimatesPayloadRequestStatus: RequestStatus;
}

export const PatientOrdersInitialState: PatientOrdersState = {
  patientOrders: [],
  loadPatientOrdersPayloadRequestStatus: RequestStatus.NotSent,
  patientEstimate: null,
  loadPatientEstimatesPayloadRequestStatus: RequestStatus.NotSent,
};

export const patientOrdersReducer = createReducer(
  PatientOrdersInitialState,
  on(PatientOrdersActions.loadPatientOrders, (state) => ({
    ...state,
    loadPatientOrdersPayloadRequestStatus: RequestStatus.Pending,
  })),
  on(
    PatientOrdersActions.loadPatientOrdersSuccess,
    (state, { patientOrders }) => ({
      ...state,
      patientOrders: patientOrders,
      loadPatientOrdersPayloadRequestStatus: RequestStatus.Success,
    })
  ),
  on(PatientOrdersActions.loadPatientOrdersFailure, (state) => ({
    ...state,
    patientOrders: [],
    loadPatientOrdersPayloadRequestStatus: RequestStatus.Failure,
  })),
  on(PatientOrdersActions.loadPatientEstimates, (state) => ({
    ...state,
    patientEstimate: null,
    loadPatientEstimatesPayloadRequestStatus: RequestStatus.Pending,
  })),
  on(
    PatientOrdersActions.loadPatientEstimatesSuccess,
    (state, { estimates }) => ({
      ...state,
      patientEstimate: estimates,
      loadPatientEstimatesPayloadRequestStatus: RequestStatus.Success,
    })
  ),
  on(PatientOrdersActions.loadPatientEstimatesFailure, (state) => ({
    ...state,
    patientEstimate: null,
    loadPatientEstimatesPayloadRequestStatus: RequestStatus.Failure,
  }))
);
